<template>
  <div class="carousel">
    <el-carousel
      autoplay
      loop
      indicator-position="none"
      :nterval="8000"
      height="600px"
      arrow="always"
    >
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <div class="img-con">
          <img class="img" :src="item.pictureAddress" alt="" />
          <div class="word">
            <div class="word1">{{item.title}}</div>
            <div class="word2">{{item.description}}</div>
            <!-- <div class="word3">化繁为简智能系统, 简单直观 便捷好用</div> -->
            <div class="word4" @click="goDetail(item)" >了解更多 →</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getCarousel } from "@/api/common";
export default {
  name: "Carousel",
  props: {
    imgUrl: Number,
    title: String,
    content: String,
    word: String,
    more: true,
  },
  data() {
    return {
      imgList: [],
    };
  },
  methods: {
    getCarousel() {
      let params = {};
      getCarousel(params).then((res) => {
        if (res.data.code == 200) {
          res.data.data.map(item=>{
            if(item.useType == '0'){
              this.imgList.push(item)
            }
          })
        } else {
        }
      });
    },
    goDetail(val) {
      this.$router.push({
        path: '/carouselDetail',
        query: {
          index: val.id
        }
      })
    }
  },
  mounted() {
    this.getCarousel();
  },
};
</script>

<style lang="less">
.carousel .el-carousel__arrow--left {
  left: 221px;
}
.carousel .el-carousel__arrow--right {
  right: 221px;
}
.carousel .el-carousel__arrow {
  font-size: 64px;
  top: 40%;
  width: 55px;
  height: 100px;
  background: transparent !important;
}
.carousel .el-carousel__arrow i {
  font-size: 64px;
  font-weight: 900;
}
.carousel {
  .img-con {
    width: 100%;
    height: 100%;
    position: relative;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .word {
    position: absolute;
    left: 457px;
    bottom: 177px;
    color: #ffffff;
    overflow: hidden;
  }
  .word1 {
    font-size: 66px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
  .word2 {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    margin-top: 27px;
  }
  .word3 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #f4f7fd;
    line-height: 36px;
    margin-top: 22px;
  }
  .word4 {
    width: 180px;
    height: 48px;
    border: 1px solid #ffffff;
    border-radius: 24px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 19px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
}
</style>