import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/productsHome",
        name: "productsHome",
        component: () =>
            import ("../views/productsHome.vue")
    },
    {
        path: "/successHome",
        name: "successHome",
        component: () =>
            import ("../views/successHome.vue")
    },
    {
        path: "/joinUs",
        name: "joinUs",
        component: () =>
            import ("../views/joinUs.vue")
    },
    {
        path: "/aboutWanxian",
        name: "aboutWanxian",
        component: () =>
            import ("../views/aboutWanxian.vue")
    },
    {
        path: "/news",
        name: "news",
        component: () =>
            import ("../views/news.vue")
    },
    {
        path: "/products",
        name: "products",
        component: () =>
            import ("../views/products.vue")
    },
    {
        path: "/newsLaunch",
        name: "newsLaunch",
        component: () =>
            import ("../views/newsLaunch.vue")
    },
    {
        path: "/carouselDetail",
        name: "carouselDetail",
        component: () =>
            import ("../views/carouselDetail.vue")
    },
    {
        path: "/successDetail",
        name: "successDetail",
        component: () =>
            import ("../views/successDetail.vue")
    },
    {
        path: "/404",
        name: "page404",
        component: () =>
            import ("../views/404.vue")
    }
];

const router = new VueRouter({
    routes
});

//vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


router.afterEach((to, from, next) => {

    window.scrollTo(0, 0)

})
export default router;