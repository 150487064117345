/**
 * 项目通用api
 * 
 */
import request from '@/utils/request'

// 轮播图查询
export function getCarousel (data) {
  return request({
    url: '/sysSlideshow/queryList',
    method: 'post',
    data
  })
}
// 产品服务列表
export function getProduct (data) {
  return request({
    url: '/sysProductService/queryList',
    method: 'post',
    data
  })
}
// 成功案例列表
export function getSuccess (data) {
  return request({
    url: '/sysSuccessCase/queryList',
    method: 'post',
    data
  })
}
// 成功案例分页
export function getSuccessPage (data) {
  return request({
    url: '/sysSuccessCase/queryPage',
    method: 'post',
    data
  })
}
// 新闻列表
export function getNews (data) {
  return request({
    url: '/sysNewsTrends/queryPage',
    method: 'post',
    data
  })
}
// 资质认证
export function getQualification (data) {
  return request({
    url: '/sysQualification/queryList',
    method: 'post',
    data
  })
}
// 联系方式
export function getContact (data) {
  return request({
    url: '/sysInformation/queryList',
    method: 'post',
    data
  })
}
// 新闻详情
export function getNewsDetail (data) {
  return request({
    url: `/sysNewsTrends/queryById?id=${data}`,
    method: 'get'
  })
}
// 加入万线
export function getJoin (data) {
  return request({
    url: '/sysJoinWlines/queryPage',
    method: 'post',
    data
  })
}
// 产品详情
export function getproDetail (data) {
  return request({
    url: `/sysProductService/queryById?id=${data}`,
    method: 'get'
  })
}
// 轮播图详情
export function getcarDetail (data) {
  return request({
    url: `/sysSlideshow/queryById?id=${data}`,
    method: 'get'
  })
}
// 成功案例详情
export function getsucDetail (data) {
  return request({
    url: `/sysSuccessCase/queryById?id=${data}`,
    method: 'get'
  })
}
// 二维码列表
export function getQrCode (data) {
  return request({
    url: 'sysQrCode/queryList',
    method: 'post',
    data
  })
}