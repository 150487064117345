<template>
  <div :class="['RightSide',show ? '' : 'RightSide2']">
      <div :class="['side_con',show ? '' : 'side_con1']">
          <div class="img_con" @click="goAbout">
              <img class="img_big" src="../assets/icon/dianhua-icon.png" alt="">
              <div class="fenge"></div>
          </div>
          <div class="img_con" @click="goAbout">
              <img class="img_big" src="../assets/icon/youjian-icon.png" alt="">
              <div class="fenge"></div>
          </div>
          <div class="img_con" @click="goAbout">
              <img class="img_big" src="../assets/icon/kefu-icon.png" alt="">
              <div class="fenge"></div>
          </div>
          <div class="img_con" @click="goAbout">
              <img class="img_big" src="../assets/icon/liuyan-icon.png" alt="">
              <div class="fenge"></div>
          </div>
      </div>
      <div class="side_bottom">
          <div class="img_con" @click="doShow">
              <img :class="['img1',show ? '' : 'img2']" src="../assets/icon/xiangshang-icon.png" alt="">
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "RightSide",
  data() {
    return {
        show: true
    };
  },
  methods: {
    doShow() {
      this.show = !this.show
      console.log(this.show)
    },
    goAbout() {
      this.$router.push({
        name: 'aboutWanxian',
        query: {
          index: 3
        }
      })
    }
  }
};
</script>

<style scoped lang="less">
.RightSide {
  position: fixed;
  right: 0px;
  bottom: 150px;
  width: 80px;
  height: 425px;
  background: #008CD7;
  border-radius: 20px 0px 0px 20px;
  z-index: 10;
  transition: height .3s;
  -webkit-transition: height .3s; /* Safari */
  overflow: hidden;
  .side_con {
    width: 80px;
    height: 340px;
    transition: height .3s;
    -webkit-transition: height .3s; /* Safari */
    overflow: hidden;
  }
  .side_con1 {
    height: 0px;
  }
  .side_bottom {
    width: 80px;
    height: 85px;
    .img1 {
      transition: transform .3s;
      -webkit-transition: transform .3s; /* Safari */
      transform:rotate(180deg);
      -ms-transform:rotate(180deg); 	/* IE 9 */
      -moz-transform:rotate(180deg); 	/* Firefox */
      -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
      -o-transform:rotate(180deg); 	/* Opera */
    }
    .img2 {
       transform:rotate(360deg);
      -ms-transform:rotate(360deg); 	/* IE 9 */
      -moz-transform:rotate(360deg); 	/* Firefox */
      -webkit-transform:rotate(360deg); /* Safari 和 Chrome */
      -o-transform:rotate(360deg); 	/* Opera */
    }
  }
  .img_con {
    width: 80px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .fenge {
    width: 64px;
    height: 1px;
    background: #EEEEEE;
    position: absolute;
    bottom: 0px;
    right: 8px;
  }
}
.RightSide2 {
  height: 85px;
}
.img_big {
  cursor: pointer;
	transition: all 0.4s
}
.img_big:hover{
	transform: scale(1.4);
}
</style>