<template>
  <div class="home">
    <Head :type=0></Head>
    <Carousel></Carousel>
    <div class="product" style="margin-top: 88px;">
      <div class="pro_word wow bounce">产品服务</div>
      <span class="pro_frame wow shake">
        <span>PRODUCT DESCRIPTION</span>
        <span class="weilei_left"></span>
        <span class="weilei_right"></span>
      </span>
    </div>
    <div class="product_con">
      <div v-if="productList[0]" class="product_list wow bounceInLeft">
        <div class="list_top">
          <img class="imgBg" :src="productList[0].pictureAddress" alt="">
        </div>
        <div class="list_bottom">
          <div class="bottom_title wow swing">{{productList[0].title}}</div>
          <p class="bottom_con">{{productList[0].description}}</p>
          <div class="bottom_more" @click="goDetail(0,productList[0].id)">了解更多</div>
        </div>
      </div>
      <div v-if="productList[1]" class="product_list wow bounceInLeft" data-wow-delay ="0.2s">
        <div class="list_top">
          <img class="imgBg" :src="productList[1].pictureAddress" alt="">
        </div>
        <div class="list_bottom">
          <div class="bottom_title wow swing">{{productList[1].title}}</div>
          <p class="bottom_con">{{productList[1].description}}</p>
          <div class="bottom_more" @click="goDetail(1,productList[1].id)">了解更多</div>
        </div>
      </div>
      <div v-if="productList[2]" class="product_list wow bounceInRight" data-wow-delay ="0.2s">
        <div class="list_top">
          <img class="imgBg" :src="productList[2].pictureAddress" alt="">
        </div>
        <div class="list_bottom">
          <div class="bottom_title wow swing">{{productList[2].title}}</div>
          <p class="bottom_con">{{productList[2].description}}</p>
          <div class="bottom_more" @click="goDetail(2,productList[2].id)">了解更多</div>
        </div>
      </div>
      <div v-if="productList[3]" class="product_list wow bounceInRight" style="margin-right:0px">
        <div class="list_top">
          <img class="imgBg" :src="productList[3].pictureAddress" alt="">
        </div>
        <div class="list_bottom">
          <div class="bottom_title wow swing">{{productList[3].title}}</div>
          <p class="bottom_con">{{productList[3].description}}</p>
          <div class="bottom_more" @click="goDetail(4,productList[4].id)">了解更多</div>
        </div>
      </div>
    </div>
    <div class="topFive">
      <div class="product">
        <div class="pro_word wow bounce" style="color:#ffffff">五大优势</div>
        <span class="pro_frame wow shake" style="border-color:#ffffff;">
          <span style="color:#ffffff;padding: 7px 39px">FIVE ADVANTAGES</span>
          <span class="weilei_left" style="background:#ffffff"></span>
          <span class="weilei_right" style="background:#ffffff"></span>
        </span>
      </div>
      <div class="five_con">
        <div class="flex_con wow bounceInLeft">
          <img src="../assets/home/five1.png" style="width:47px;height:44px" alt="">
          <p>25年后勤服务实践经验</p>
        </div>
        <img class="wow bounceInDown" src="../assets/home/duihao.png" style="width:43px;height:43px" alt="">
      </div>
      <div class="five_con">
        <div class="flex_con wow bounceInLeft" data-wow-delay ="0.2s">
          <img src="../assets/home/five2.png" style="width:47px;height:44px" alt="">
          <p>自主研发团队的积极响应</p>
        </div>
        <img class="wow bounceInDown" data-wow-delay ="0.2s" src="../assets/home/duihao.png" style="width:43px;height:43px" alt="">
      </div>
      <div class="five_con">
        <div class="flex_con wow bounceInLeft" data-wow-delay ="0.4s">
          <img src="../assets/home/five3.png" style="width:47px;height:44px" alt="">
          <p>坚守后勤服务的恒心   长远战略眼光</p>
        </div>
        <img class="wow bounceInDown" data-wow-delay ="0.4s" src="../assets/home/duihao.png" style="width:43px;height:43px" alt="">
      </div>
      <div class="five_con">
        <div class="flex_con wow bounceInLeft" data-wow-delay ="0.6s">
          <img src="../assets/home/five4.png" style="width:47px;height:44px" alt="">
          <p>响应客户需求   持续提供解决方案</p>
        </div>
        <img class="wow bounceInDown" data-wow-delay ="0.6s" src="../assets/home/duihao.png" style="width:43px;height:43px" alt="">
      </div>
      <div class="five_con">
        <div class="flex_con wow bounceInLeft" data-wow-delay ="0.8s">
          <img src="../assets/home/five5.png" style="width:47px;height:44px" alt="">
          <p>线上线下全面解决方案</p>
        </div>
        <img class="wow bounceInDown" data-wow-delay ="0.8s" src="../assets/home/duihao.png" style="width:43px;height:43px" alt="">
      </div>
    </div>
    <div class="product">
      <div class="pro_word wow bounce">成功案例</div>
      <span class="pro_frame wow shake" style="padding: 0px 58px">
        <span>SUCCESS CASE</span>
        <span class="weilei_left"></span>
        <span class="weilei_right"></span>
      </span>
    </div>
    <div class="success">
      <el-carousel indicator-position="outside" :loop="true" :nterval="8000" height="650px" arrow="never" style="width:100%">
        <el-carousel-item v-for="(item,index) in successList" :key="index">
          <div class="list_con">
            <div class="carousel_con" v-for="(ite,ind) in item.child" :key="ind">
              <div class="car_img">
                <img class="imgBg" :src="ite.pictureAddress" alt="">
              </div>
              <h6 class="car_title">{{ite.title}}</h6>
              <p class="car_word">{{ite.description}}</p>
              <div class="car_more"><span @click="goSuccess(ite)">more</span> <span style="position: relative;top: 2px;">></span></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="news">
      <div class="product">
        <div class="pro_word wow bounce" style="color:#ffffff;margin-top:59px">新闻动态</div>
        <span class="pro_frame wow shake" style="border-color:#ffffff;">
          <span style="color:#ffffff;padding: 0px 100px">NEWS</span>
          <span class="weilei_left" style="background:#ffffff"></span>
          <span class="weilei_right" style="background:#ffffff"></span>
        </span>
      </div>
      <div class="news_con">
        <div class="news_left wow bounceInLeft">
          <div class="left_list wow lightSpeedIn" v-for="(item,index) in newsList" :key="index" :class="[index == newsIndex?'left_list_color':'']" @mouseenter="enter(index)">
            <div style="display:flex">
              <p class="list_left">{{item.createTime.substring(0,4)}}</p>
              <p class="list_right">{{item.title}}</p>
            </div>
            <div style="display:flex">
              <p class="list_left1">{{item.createTime.substring(5,10)}}</p>
              <p class="list_right1">{{item.description}}</p>
            </div>
            <div style="width:100%;padding:16px 41px 16px 50px;box-sizing: border-box;">
              <div style="height:1px;width:100%;background:#ffffff;"></div>
            </div>
          </div>
          <div class="more wow flipInY" @click="goNews">MORE ></div>
        </div>
        <div class="news_right wow bounceInRight">
          <div v-if="newsList[newsIndex]" class="right_con">
            <div class="right_top">
              <img class="imgBg" :src="newsList[newsIndex].pictureAddress" alt="">
            </div>
            <div class="right_bottom">{{newsList[newsIndex].title}}</div>
          </div>
        </div>
      </div>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getProduct,getSuccess,getNews } from "@/api/common";
export default {
  name: "Home",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      productList: [],
      successList: [],
      pageSize: 0,
      newsList: [],
      newsIndex: 0
    }
  },
  methods: {
    goDetail(index,id) {
        this.$router.push({
          path: '/products',
          query: {
            index: index,
            id: id
          }
        })
    },
    goSuccess() {
      this.$router.push('/successHome')
    },
    goNews() {
      this.$router.push('/news')
    },
    // 产品服务列表
    getProduct() {
      let params = {};
      getProduct(params).then((res) => {
        if (res.data.code == 200) {
          this.productList = res.data.data
        } else {
        }
      });
    },
    // 成功案例列表
    getSuccess() {
      let params = {};
      getSuccess(params).then((res) => {
        if (res.data.code == 200) {
          this.pageSize = Math.ceil(res.data.data.length/3)
          let array = []
          for(let i=0; i<this.pageSize; i++){
            let arr = []
            for(let j=0; j<3; j++){
              let obj = res.data.data[(i*3)+j]
              if(obj){
                arr.push(obj)
              }
            }
            array.push({child:arr})
          }
          this.successList = array
        } else {
        }
      });
    },
    enter(index) {
      this.newsIndex = index
    },
    //新闻分页
    getNews() {
      let params = {
        current: 1,
        size: 3,
      };
      getNews(params).then((res) => {
        if (res.data.code == 200) {
          this.newsList = res.data.data.records
        } else {

        }
      });
    },
  },
  created() {
    
  },
  mounted() {
    this.getProduct()
    this.getSuccess()
    this.getNews()
    let wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
  },
};
</script>
<style scoped lang="less">
.home{
  .product{
    height: 62px;
    margin: 0 auto;
    margin-top: 54px;
    margin-bottom: 91px;
    text-align: center;
    .pro_word {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .pro_frame {
      font-size: 30px;
      font-family: Myriad Pro;
      font-weight: 400;
      color: #008CD7;
      padding: 0px 13px;
      border: 2px solid #008CD7;
      border-top: none;
      white-space:nowrap;
      position: relative;
      display: inline-block;
    }
    .weilei_left {
      display: inline-block;
      width: 4%;
      height: 2px;
      background:#008CD7;
      position: absolute;
      left: -2px;
      top: -2px;
    }
    .weilei_right {
      display: inline-block;
      width: 4%;
      height: 2px;
      background:#008CD7;
      position: absolute;
      right: -2px;
      top: -2px;
    }
  }
  .product_con {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    .product_list {
      width: 298px;
      margin-right: 29px;
    }
    .list_top {
      width: 298px;
      height: 298px;
      overflow: hidden;
    }
    .list_bottom {
      width: 298px;
      height: 245px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 0px 20px;
      border: 1px solid #BFBFBF;
      margin-bottom: 88px;
      color: #333333;
      transition: all .5s;
      -webkit-transition: all .5s; /* Safari */
    }
    .list_bottom:hover {
      background: #008CD7;
      color: #ffffff;
      .bottom_more {
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
    .bottom_title {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      margin: 33px 0px 51px 0px;
      text-align: center;
    }
    .bottom_con {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    .bottom_more {
      width: 100px;
      height: 38px;
      border: 1px solid #008CD7;
      margin: 0 auto;
      margin-top: 25px;
      color: #008CD7;
      text-align: center;
      line-height: 38px;
      cursor: pointer;
    }
  }
  .topFive {
    width: 100%;
    height: 620px;
    background: url(../assets/img/fiveBg.png) no-repeat;
    background-size:100% 100%;
    overflow: hidden;
    .five_con {
      display: flex;
      width: 992px;
      justify-content: space-between;
      align-content: center;
      margin: 0px auto;
      margin-bottom: 33px;
      .flex_con {
        display: flex;
        align-items: center;
      }
      p {
        font-size: 28px;
        font-family: DFLiJinHeiW8;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 85px;
      }
    }
  }
  .success {
    width: 1280px;
    margin: 0 auto;
    .list_con {
      width: 100%;
      display: flex;
      box-shadow: 0px 3px 32px 0px rgba(54, 54, 54, 0.15);
    }
    .carousel_con {
      width: 410px;
      height: 600px;
      background: #FFFFFF;
      box-shadow: 0px 3px 32px 0px rgba(54, 54, 54, 0.15);
      padding: 25px;
      position: relative;
      box-sizing: border-box;
      margin: 0px 10px;
    }
    .car_img {
      width: 360px;
      height: 270px;
      margin-bottom: 31px;
      background: #BFBFBF;
      overflow: hidden;
    }
    .car_title {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-bottom: 24px;
    }
    .car_word {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
    }
    .car_more {
      width: 358px;
      height: 54px;
      border: 1px solid #008CD7;
      text-align: center;
      line-height: 54px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 20px;
      color: #008CD7;
      position: absolute;
      left: 25px;
      bottom: 25px;
      cursor: pointer;
      transition: all .5s;
      -webkit-transition: all .5s; /* Safari */
    }
    .car_more:hover {
      background: #008CD7;
      color: #ffffff;
    }
  }
  .news {
    width: 100%;
    height: 832px;
    margin-top: 80px;
    overflow: hidden;
    background: #031B33;
    background: url(../assets/home/news.png) no-repeat;
    background-size:100% 100%;
    box-sizing: border-box;
    .news_con {
      width: 1280px;
      margin: 0px auto;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .news_left {
        flex: 1;
      }
      .news_right {
        width: 508px;
      }
      .right_con {
        width: 508px;
        height: 480px;
        background: #FFFFFF;
      }
      .right_top {
        width: 508px;
        height: 381px;
        background: #BFBFBF;
        overflow: hidden;
      }
      .right_bottom {
        width: 508px;
        height: 98px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        padding: 10px;
        box-sizing: border-box;
      }
      .left_list {
        color: #ffffff;
      }
      .left_list_color {
        color: #008cd7;
      }
      .list_left {
        width: 100px;
        text-align: right;
        font-size: 26px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 40px;
        margin-right: 20px;
      }
      .list_right {
        text-align: left;
        font-size: 26px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 40px;
      }
      .list_left1 {
        text-align: right;
        width: 100px;
        font-size: 22px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 40px;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .list_right1 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        padding: 10px 41px 0px 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .more {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-decoration: underline;
        color: #FFFFFF;
        line-height: 30px;
        text-align: right;
        margin-right: 50px;
        margin-top: 40px;
        cursor: pointer;
      }
    }
  }
  .imgBg {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
	  transition: all 0.4s
  }
  .imgBg:hover {
    transform: scale(1.2);
  }
}
</style>
<style lang="less">
.success {
 .el-carousel__button {
   width: 20px;
   height: 5px;
 }
 .el-carousel__indicators--outside button {
   opacity: 1;
   background-color: #DCDCDC;
 }
 .el-carousel__indicator.is-active button {
   background-color: #1395D9;
 }
}
  
</style>
