<template>
  <div class="head" :class="{'headF':isFixed}" id="head">
    <div class="head_con">
      <div class="con_left">
        <img class="logo" src="../assets/icon/logo.png" @click="goHome"/>
      </div>
      <div class="con_right">
        <div class="right_top">
          <div class="icon_con" @click="goAbout">
            <img class="icon" src="../assets/icon/lianxiwomen-icon.png" />
            <span>联系我们</span>
          </div>
          <div class="icon_con" @click="goNews">
            <img class="icon" src="../assets/icon/xinwendongtai-icon.png" />
            <span>新闻动态</span>
          </div>
          <div class="icon_con">
            <img class="icon" src="../assets/icon/yuyan-icon.png" />
            <span :class="{'chinese':flag == false}" @click="tabCE('c')">中文</span>
            <!-- /
            <span :class="{'english':flag == true}" @click="tabCE('e')">En</span> -->
          </div>
        </div>
        <!-- <div class="right_search">
          <input type="text">
          <div class="img_con">
            <img src="../assets/icon/search.png" alt="">
          </div>
        </div> -->
        <div class="right_bottom">
          <div :class="{'wordColor':type == 0}" @click="tabIndex('/')">首页</div>
          <div :class="{'wordColor':type == 1}" @click="tabIndex('/productsHome')">产品服务</div>
          <div :class="{'wordColor':type == 2}" @click="tabIndex('/successHome')">成功案例</div>
          <div :class="{'wordColor':type == 3}" @click="tabIndex('/joinUs')">加入万线</div>
          <div :class="{'wordColor':type == 4}" @click="tabIndex('/aboutWanxian')">关于万线</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  props: {
    type: Number
  },
  data() {
    return {
      flag: false,
      isFixed: false
    };
  },
  methods: {
    tabCE(val) {
      if(val === "c") {
        this.flag = false
      }else {
        this.flag = true
      }
    },
    tabIndex(val) {
      this.$router.push(val)
    },
    goNews() {
      this.$router.push('/news')
    },
    goHome() {
      this.$router.push('/')
    },
    goAbout() {
      this.$router.push({
        name: 'aboutWanxian',
        query: {
          index: 3
        }
      })
    }
    // handleScroll () {
    //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   let offsetTop = document.querySelector('#head').offsetTop;
    //   console.log(scrollTop,offsetTop)
    //   if(scrollTop>50){
    //     this.isFixed = true
    //   }else{
    //     this.isFixed = false
    //   }
    // }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.headF {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
.con_right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.head {
  width: 100%;
  height: 148px;
  overflow: hidden;
  background-color: #ffffff;
}
.head_con {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 170px;
  height: 56px;
  margin-top: 41px;
  margin-left: 26px;
  display: block;
}
.right_top {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold;
  margin-top: 14px;
  align-items: center;
  color: #333333;
  .icon_con {
    display: flex;
  }
  .icon {
    width: 20px;
    height: 18px;
    margin-right: 7px;
    margin-left: 10px;
    display: block;
  }
  span {
    cursor: pointer;
  }
}
.chinese,
.english {
  color: #008cd7;
  text-decoration: underline;
}
.right_search {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  input {
    width: 200px;
    height: 26px;
    background:none;  
	  outline:none;  
	  border: 1px solid #333333;
    border-radius: 14px;
    padding: 0px 8px;
  }
  input:focus { outline: none; } 
  .img_con{
    width: 48px;
    height: 28px;
    border-radius: 14px;
    background: #008CD7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    img {
      width: 20px;
      height: 20px;
      display: block;
    }
  }
}
.right_bottom {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold;
  margin-top: 25px;
  align-items: center;
  color: #333333;
  div {
    margin-left: 80px;
    cursor: pointer;
  }
  div:hover {
    color: #008cd7;
  }
  .wordColor {
  color: #008cd7;
}
}
</style>
